import * as React from "react"
import Layout from "../components/layout"
import Hero from "../components/Hero"
import Intro from "../components/Intro"
import HowItWorks from "../components/HowItWorks"
import Outro from "../components/Outro"

const Base = ( { data } ) => (
    <Layout>
        <Hero heroData={data.heroData} />
        <div class="pb-10 lg:pb-20">
            <Intro introData={data.introData} />
            <HowItWorks howData={data.howData} />
            <Outro outroData={data.outroData} />
        </div>
    </Layout>
)

export default Base;