import * as React from "react"
import Base from "../components/Base"

import hero1 from "../images/Agents Hero.png"
import OwnersBody1 from "../images/Agents Body 1.jpg"
import OwnersBody2 from "../images/Agents Body 2.jpg"
import Handyman1 from "../images/handyman5.svg"
import Handyman2 from "../images/handyman2.svg"
import icon1 from "../images/8.svg"
import icon2 from "../images/6.svg"
import icon3 from "../images/7.svg"
import icon4 from "../images/9.svg"
import icon5 from "../images/10.svg"

export default function AgentsPage() {
  return (
    <Base data={data} />
  )
}

const data = {
  heroData: {
    image: hero1,
    headline: "Stand out from the market.",
    subtitle: "Homeplan offers property sales agents a unique product and point of difference in the market. We also help strengthen your relationships with future and past clients. We offer vendors and home buyers annual maintenance plans for properties at the point of sale.",
    subtitle2: ""
  },
  introData: {
    tagline1: "Give buyers peace of mind when purchasing through your agency.",
    image1: OwnersBody1,
    tagline2: "Grow your business and create a",
    tagline2High: "point of difference in the market.",
    body: "",
    bulletPunch: "Our home maintenance plans help you grow your business by offering:",
    bullets: [
      "A unique point of difference in the market", 
      "A listing tool to in sales pitches", 
      "A negotiation tool with buyers", 
      "Home buyers’ peace of mind when purchasing", 
      "Increased sale prices and commissions",
      "A vendor or homebuyer paid product",
      "A strong ongoing relationship with home owners"
    ],
    image2: Handyman1
  },
  howData: {
    hows: [
      { icon: icon1, text: "Partner with us to offer our maintenance plans to your clients" },
      { icon: icon2, text: "A vendor can prepay a maintenance plan for their property for 1, 2, or 3 years" },
      { icon: icon3, text: "Our maintenance plan can be marketed with the property and given to home buyers" },
      { icon: icon4, text: "Use the plan to incentivise the sale and as a negotiation tool to achieve the best price" },
      { icon: icon5, text: "We help you build and maintain long lasting relationships with the home buyer post sale" },
    ],
    image: Handyman2,
    taglineHigh: "Create long term relationships",
    tagline: "with homeowners."
  },
  outroData: {
    close: "We currently only offer our maintenance plans exclusively through partnerships with real estate agencies across the Sydney Metro area.",
    image: OwnersBody2
  }
}