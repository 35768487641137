import * as React from "react"

const Intro = ({ introData }) => (
    <div class="flex md:flex-row flex-col-reverse pt-8 md:pt-20">
        <div class="md:w-1/2 md:mr-16">
            <div class="pt-8 md:pt-0">
                <img src={introData.image1} />
            </div>
            <div class="mt-10 text-hp-gold text-xl md:text-4xl font-semi-bold leading-tight">
                {introData.tagline1}
            </div>
        </div>
        <div class="md:w-1/2 md:mr-16">
            <div class="md:w-5/6 md:pt-8 text-xl md:text-4xl font-semi-bold text-hp-grey-55 leading-tight">
                {introData.tagline2} <span class="text-hp-gold">{introData.tagline2High}</span>
            </div>
            <div class="flex pt-8">
                <div class="lg:w-3/4">
                    <div class="text-md font-semi-bold leading-tight">
                        {introData.body}
                    </div>
                    <div class="mt-8 text-md">
                        {introData.bulletPunch}
                    </div>
                    <ul class="list-dash list-inside text-md leading-normal">
                        {introData.bullets.map((item) => (
                            <li>{item}</li>
                        ))}
                    </ul>
                </div>
                <div class="w-72 pl-4 -mr-32 -mb-16 hidden lg:block">
                    <img class="relative z-10" src={introData.image2} />
                </div>
            </div>
        </div>
    </div>
);

export default Intro;
