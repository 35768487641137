import * as React from "react"

const Outro = ({ outroData }) => (
    <div class="flex md:flex-row flex-col-reverse md:mt-32 mt-20">
        <div class="md:w-1/2 lg:mt-10 mt-6">
            <div class="md:w-11/12 text-hp-grey-55 text-xl md:text-4xl font-semi-bold leading-tight">
                {outroData.close}
            </div>
            <div class="text-hp-gold text-xl md:text-4xl font-semi-bold leading-tight pt-10 w-3/4">
                For more information please contact us at <a href="mailto: hello@homeplan.com.au">hello@homeplan.com.au</a>
            </div>
        </div>
        <div class="md:w-1/2">
            <img class="md:pl-6 lg:pl-16" src={outroData.image} />
        </div>
    </div>
)

export default Outro;
