import * as React from "react"

const HowItWorks = ({ howData }) => (
    <div class="full-bleed bg-hp-gold-3">
        <div class="wrapper">
            <div class="pt-8 md:pt-20 text-center md:text-left text-4xl xl:text-6xl font-semi-bold text-hp-grey-55">How it works</div>
            <div class="flex flex-wrap">
                {
                    howData.hows.map((item) => (
                    <div class="mx-auto w-40 text-center md:text-left">
                        <img class="pt-8 h-32 my-4 mx-auto md:mx-1" src={item.icon} />
                        <div class="text-md text-hp-grey leading-tight">{item.text}</div>
                    </div>
                ))}
            </div>
            <div class="flex mt-16">
                <div class="w-1/2 -mb-16 hidden md:block">
                    <img class="lg:w-3/5 md:h-64" src={howData.image} />
                </div>
                <div class="md:w-1/2 lg:pt-16 lg:pb-8 lg:pr-32 h-min text-xl md:text-4xl font-semi-bold text-hp-grey-55 leading-tight">
                    <span class="text-hp-gold">{howData.taglineHigh} </span>{howData.tagline}
                </div>
            </div>
        </div>
    </div>
)

export default HowItWorks;