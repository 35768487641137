import * as React from "react"

const Hero = ({ heroData }) =>
(
  <div class="full-bleed relative">
    <div class="wrapper py-20 md:py-48 bg-black/20 xl:bg-black/0">
      <div class="font-bold text-white leading-none text-center text-4xl md:text-5xl lg:text-6xl xl:w-3/5 xl:text-left">
        {heroData.headline}
      </div>
      {heroData.subtitle &&
        <div class="font-semi-bold text-white mt-6 text-center leading-tight md:leading-tight text-sm sm:text-md md:text-xl xl:w-5/12 xl:text-left">
          {heroData.subtitle}
        </div>
      }
      {heroData.subtitle2 &&
        <div class="font-semi-bold text-white mt-6 text-center leading-tight md:leading-tight text-sm sm:text-md md:text-xl xl:w-5/12 xl:text-left">
          {heroData.subtitle2}
        </div>
      }
    </div>
    <img class="absolute top-0 right-0 h-full w-full object-cover -z-10" src={heroData.image}></img>
  </div>
);

export default Hero;